import React, {useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom"
import {
    Button,
    Form,
    Row,
    Col,
    DatePicker,
    Input,
    Select,
    Table,
    Pagination,
    Modal,
    TreeSelect,
    InputNumber,
    Radio
} from 'antd'
import {SaveOutlined, SearchOutlined, ReloadOutlined, RollbackOutlined, SelectOutlined} from '@ant-design/icons'
import {wmsPublic, Product, Term} from '../../../components/wms/config'
import PageTop from '../../../components/layout/PageTop'
import SearchArea from '../../../components/layout/SearchArea'
import PageBottom from '../../../components/layout/PageBottom'
import LookupProductGoods from '../../../components/wms/LookupProductGoods'
import common from "../../../utils/common"

function InstallPackage() {
    const sRef = useRef(null)
    const [form] = Form.useForm()
    const [treeData1, setTreeData1] = useState([]);
    const [treeData2, setTreeData2] = useState([]);
    const [treeData3, setTreeData3] = useState([]);
    const [oriData2, setOriData2] = useState([])
    const [productListShow, setProductListShow] = useState(false)
    const [tableData, setTableData] = useState([])
    const [flag, setFlag] = useState(0)
    const [tableType, setTableType] = useState('inDetail')

    let getTermData = (taxonomy) => {
        let params = {
            taxonomy: taxonomy,
            status: 1,
            ownerId: common.getUser().company.id
        }

        common.loadingStart()
        common.ajax('get', '/wms/term/list', params).then(data => {
            let arr = getTree(data.terms, '')
            if (taxonomy === Term.TAXONOMY_TWO) {
                setOriData2(data.terms)
                setTreeData2(arr)
            } else if (taxonomy === Term.PRODUCT_CATEGORY) {
                setTreeData3(arr)
            }
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getTermData(Term.TAXONOMY_TWO)
        getTermData(Term.PRODUCT_CATEGORY)
    }, [])

    const getTree = (data, fid) => {
        let res = [];
        let level = data.filter(item => item.parentId === fid)
        level.map(item => {
            let _t = {
                label: item.name,
                value: item.id
            }
            if (data.findIndex(d => d.parentId == item.id) != -1) {
                // 有子集
                _t.children = getTree(data, item.id)
            }
            res.push(_t)
        })
        return res
    };
    const onGetData = (params, from) => {
        if (params == undefined) {
            params = {}
        }
        common.ajax('get', '/wms/defaultProductQuantity/list', {
            ...params,
            ownerId: common.getUser().company.id,
            limit: 9999 // 临时放开，因为如果按分页加载的话 保存 可能会丢数据
            // status: '',
            // productName: '',
            // productNumber: '',
            // termTenantId: '',
            // termStatisticsId: '',
            // termProductId: '',
            // spec: ''
        }).then((res) => {
            if (res && res.list) {
                let _list = []
                res.list.map(item => {
                    let _toItem = {
                        id: item.productId,
                        productId: item.productId,
                        name: item.productName,
                        number: item.productNumber,
                        unit: item.unit,
                        terms: {},
                        status: item.status,
                        carQuantity: item.carQuantity,
                        commercialQuantity: item.commercialQuantity,
                        spec: item.spec,
                        quantity: item.quantity,
                        salePrice: item.salePrice
                    }
                    if (item.termTenantId) {
                        _toItem.terms.category1 = {
                            termId: item.termTenantId,
                            name: item.termTenantName
                        }
                    }
                    if (item.termStatisticsId) {
                        _toItem.terms.category2 = {
                            termId: item.termStatisticsId,
                            name: item.termStatisticsName
                        }
                    }
                    if (item.termProductId) {
                        _toItem.terms.productCategory = {
                            termId: item.termProductId,
                            name: item.termProductName
                        }
                    }
                    _list.push({
                        ..._toItem,
                        kid: new Date().getTime() + Math.random()
                    })
                })
                doList(_list, true, from)
            }
        }).finally(() => {
        })
    }
    const doList = (val, refresh, from) => {
        // doList 之前是按照分类展示数据，现在增加一下，根据tableType判断 inDetail就是原来的分类明细 byPosition就是按部位
        // 配件管理分类 productCategory 材质  集团统计分类 category2 部位
        // 
        if (from == 'byPosition') {
            doListByPosition(val, refresh, from)
            return false
        }
        let res_res_list = []
        // if (flag) {
        val.map(item => {
            if (item.from == 'lookup') {
                if (res_res_list.findIndex(vitem => vitem.productId == item.productId) == -1) {
                    res_res_list.push({
                        productId: item.productId,
                        productName: item.name,
                        productNumber: item.number,
                        terms: item.terms,
                        children: [],
                        kid: Math.random() + new Date().getTime()
                    })
                }
            } else {
                if (res_res_list.findIndex(vitem => vitem.productId == item.productId) != -1) {
                    res_res_list[res_res_list.findIndex(vitem => vitem.productId == item.productId)].children.push(item)
                } else {
                    res_res_list.push({
                        productId: item.productId,
                        productName: item.name,
                        productNumber: item.number,
                        terms: item.terms,
                        children: [item],
                        kid: Math.random() + new Date().getTime()
                    })
                }
            }
        })
        let res_list = []
        res_res_list.map(item => {
            if (item.terms == undefined) {
                item.terms = {}
            }
            if (item.terms.productCategory == undefined) {
                item.terms.productCategory = {
                    termId: -1,
                    name: '其他'
                }
            }
            if (res_list.findIndex(vitem => vitem.productCategoryId == item.terms.productCategory.termId) != -1) {
                res_list[res_list.findIndex(vitem => vitem.productCategoryId == item.terms.productCategory.termId)].children.push(item)
            } else {
                res_list.push({
                    productCategoryId: item.terms.productCategory.termId,
                    productCategoryName: item.terms.productCategory.name,
                    children: [item],
                    kid: Math.random() + new Date().getTime()
                })
            }
        })

        let _t_list = [...tableData]
        if (refresh) {
            _t_list = []
        }
        // 新数据
        res_list.map(item => {
            // item 新材质
            if (_t_list.findIndex(fitem => fitem.productCategoryId == item.productCategoryId) != -1) {
                // 有这个材质
                let ttt = _t_list[_t_list.findIndex(fitem => fitem.productCategoryId == item.productCategoryId)].children
                // 这个材质的档案们
                item.children.map(mitem => {
                    // 新材质的档案门 mitem 新材质的档案
                    // 旧档案们有没有新材质档案
                    if (ttt.findIndex(mmitem => mmitem.productId == mitem.productId) != -1) {
                        // 有 把部位扔进去 
                        ttt[ttt.findIndex(mmitem => mmitem.productId == mitem.productId)].children = [...ttt[ttt.findIndex(mmitem => mmitem.productId == mitem.productId)].children, ...mitem.children]
                    } else {
                        ttt.push(mitem)
                    }
                })
                _t_list[_t_list.findIndex(fitem => fitem.productCategoryId == item.productCategoryId)].children = [...ttt]

            } else {
                // 没有这个材质
                _t_list.push(item)
            }
        })
        setTableData(_t_list)
        // setTableData(res_list)
        setFlag(flag + 1)
        setProductListShow(false)
    }
    const doListByPosition = (val, refresh, from) => {
        if (val) {
            let res_by = Object.groupBy(val, (item) => item.terms.category2.name)
            res_by = Object.values(res_by)
            let res_by_table = []
            res_by.map(item => {
                res_by_table.push({
                    positionName: item[0].terms.category2.name,
                    positionId: item[0].terms.category2.termId,
                    children: [...item],
                    kid: Math.random() + new Date().getTime()
                })
            })
            setTableData(res_by_table)
        }
    }
    useEffect(() => {
        onGetData()
    }, [])
    return (
        <>
            <PageTop title="用品规划">
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    setTableType('byPosition')
                    // onGetData({}, 'byPosition')
                    // 改一改 不重新加载
                    // 基于现有数据 转换方向
                    if (tableType == 'inDetail') {
                        let result = []
                        if (tableData.length) {
                            tableData.map(parItem => {
                                parItem.children.map(termItem => {
                                    termItem.children.map(chi => {
                                        // delete chi.terms
                                        result.push({
                                            ...chi,
                                            parentTermId: parItem.parentTermId,
                                            parentTermName: parItem.parentTermName,
                                            termId: termItem.termId,
                                            termName: termItem.termName
                                        })
                                    })
                                })
                            })
                        }
                        // result = result.map(item => {return {...item, name: item.terms.category2.name}})
                        doListByPosition(result)
                    }
                }}>按部位批量定义</Button>
                <Button type="primary" icon={<SaveOutlined/>} onClick={() => {
                    let result = []
                    if (tableType == 'inDetail') {
                        if (tableData.length) {
                            tableData.map(parItem => {
                                parItem.children.map(termItem => {
                                    termItem.children.map(chi => {
                                        // delete chi.terms
                                        result.push({
                                            ...chi,
                                            parentTermId: parItem.parentTermId,
                                            parentTermName: parItem.parentTermName,
                                            termId: termItem.termId,
                                            termName: termItem.termName
                                        })
                                    })
                                })
                            })
                        }
                    } else {
                        if (tableData.length) {
                            tableData.map(parItem => {
                                parItem.children.map(termItem => {
                                    result.push({
                                        ...termItem
                                    })
                                })
                            })
                        }
                    }
                    result = result.map(item => {
                        return {...item, name: item.terms.category2.name}
                    })
                    common.ajax('post', '/wms/defaultProductQuantity/save', {
                        ownerId: common.getUser().company.id,
                        items: result
                    }).then((res) => {
                        common.toast('保存成功')
                        setTableType('inDetail')
                        onGetData({}, 'inDetail')
                    }).finally(() => {
                    })
                }}>保存</Button>
                <Button icon={<RollbackOutlined/>} onClick={() => {
                    setTableType('inDetail')
                    onGetData({}, 'inDetail')
                }}>刷新</Button>
            </PageTop>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"} form={form} onFinish={(vals) => {
                    Object.keys(vals).map(key => {
                        vals[key] = vals[key] == undefined ? '' : vals[key]
                    })
                    onGetData(vals)
                }} initialValues={{}}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="商品编号" className='label-character-5' name="productNumber">
                                <Input autoComplete='off' type="text" placeholder={'输入商品编号'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="商品名称" className='label-character-5' name="productName">
                                <Input autoComplete='off' type="text" placeholder={'输入商品名称'}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="状态" name="status">
                                <Select>
                                    <Select.Option value="" key={0}>全部</Select.Option>
                                    <Select.Option value={1} key={1}>禁用</Select.Option>
                                    <Select.Option value={2} key={2}>启用</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="用料备注" name="spec">
                                <Input autoComplete='off' type="text" placeholder={'输入用料备注'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        {/* <Col span={6}>
                            <Form.Item label="集团管理分类" className='label-character-4' name="category1">
                                <TreeSelect
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                />
                            </Form.Item>
                        </Col> */}
                        <Col span={6}>
                            <Form.Item label="集团统计分类" className='label-character-5' name="termStatisticsId">
                                <TreeSelect
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData2}
                                    treeDefaultExpandAll
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="配件管理分类" className='label-character-5' name="termProductId">
                                <TreeSelect
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData3}
                                    treeDefaultExpandAll
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    form.submit()
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            {
                tableType == 'inDetail' ?
                    <Table
                        rowKey={record => record.kid}
                        columns={[
                            // {
                            //     title: '序号',
                            //     key: 'index',
                            //     width: 100,
                            //     fixed: 'left',
                            //     render: (text, record, index) => `${index + 1}`,
                            // },
                            {
                                title: '材质',
                                dataIndex: 'productCategoryName',
                                key: 'productCategoryName',
                                ellipsis: true,
                                width: 120,
                                render: (text, record) => {
                                    if (record.isEmptyRecord) {
                                        return <Input
                                            style={{
                                                whiteSpace: 'pre-wrap',
                                                marginLeft: tableData.length ? '-20px' : '0'
                                            }}
                                            ref={sRef}
                                            autoComplete="off"
                                            placeholder={"输入编号 回车"}
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setProductListShow(true)
                                                        // console.log(sRef.current.state.value)
                                                        // setModalNumber(1)
                                                        // setModalTitle(action === Sale.TYPE_BOUTIQUE ? '精品选择' : (action === Sale.TYPE_PART ? '配件选择' : "商品选择"))
                                                        // setVisible(true)
                                                    }}/>
                                            }
                                            onKeyDown={(e) => {
                                                if (sRef.current.state.value && sRef.current.state.value.length > 0 && e.keyCode === 13) { // Enter
                                                    // ajax查询数据
                                                    // getData(newValue)
                                                    // console.log(sRef.current.state.value)
                                                }
                                            }}
                                        />
                                    } else {
                                        return text
                                    }
                                }
                            },
                            {
                                title: '商品编号',
                                dataIndex: 'productNumber',
                                key: 'productNumber',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '商品名称',
                                dataIndex: 'productName',
                                key: 'productName',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '部位',
                                dataIndex: ['terms', 'category2', 'name'],
                                key: 'unit',
                                ellipsis: true,
                                width: 160,
                                render: (text, record, index) => {
                                    if (record.isEmptyRecord) {
                                        return ''
                                    }
                                    if (record.productNumber != undefined && record.status == undefined) {
                                        return <div style={{display: 'flex'}}>
                                            <Select style={{width: '80%'}} onChange={(e) => {
                                                record.tttag = e
                                            }}>
                                                {
                                                    treeData2.map(c => {
                                                        return <Select.Option value={c.label}
                                                                              key={c.value}>{c.label}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                            <Button onClick={() => {
                                                if (record.tttag) {
                                                    record.children.unshift({
                                                        "productId": record.productId,
                                                        "name": record.tttag,
                                                        "number": record.productNumber,
                                                        "unit": "个",
                                                        "quantity": "",
                                                        "salePrice": "",
                                                        "carQuantity": "",
                                                        "commercialQuantity": "",
                                                        "status": 2,
                                                        "spec": "",
                                                        "terms": {'category2': {'name': record.tttag}},
                                                        "kid": Math.random() + new Date().getTime()
                                                    })
                                                    setFlag(flag + 1)
                                                } else {
                                                    common.toast('请选择部位添加')
                                                }
                                            }}>添加</Button>
                                        </div>
                                    }
                                    return text

                                }
                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                ellipsis: true,
                                width: 80,
                            },
                            // {
                            //     title: '最新进价',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },
                            {
                                title: '轿车出库用量',
                                dataIndex: 'carQuantity',
                                key: 'carQuantity',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    if (record.isEmptyRecord || record.productCategoryId != undefined) {
                                        return ''
                                    }
                                    if (record.productNumber != undefined && record.status == undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0.01}
                                                    defaultValue={record.carQuantity}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].carQuantity = val
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.carQuantity = record.carQuantity
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].children = record.children
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0.01}
                                            value={record.carQuantity}
                                            onChange={(val) => {
                                                record.carQuantity = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            {
                                title: '商务车出库用量',
                                dataIndex: 'commercialQuantity',
                                key: 'commercialQuantity',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    if (record.isEmptyRecord || record.productCategoryId != undefined) {
                                        return ''
                                    }
                                    if (record.productNumber != undefined && record.status == undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0.01}
                                                    value={record.commercialQuantity}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].commercialQuantity = val
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.commercialQuantity = record.commercialQuantity
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].children = record.children
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0.01}
                                            value={record.commercialQuantity}
                                            onChange={(val) => {
                                                record.commercialQuantity = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            // {
                            //     title: '轿车成本',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },
                            // {
                            //     title: '商务车成本',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },
                            {
                                title: '零售价',
                                dataIndex: 'salePrice',
                                key: 'salePrice',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    // return text
                                    if (record.isEmptyRecord || record.productCategoryId != undefined) {
                                        return ''
                                    }
                                    if (record.productNumber != undefined && record.status == undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0}
                                                    value={record.salePrice}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].salePrice = val
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.salePrice = record.salePrice
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].children = record.children
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0}
                                            value={record.salePrice}
                                            onChange={(val) => {
                                                record.salePrice = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            {
                                title: '销售数量',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    // return text
                                    if (record.isEmptyRecord || record.productCategoryId != undefined) {
                                        return ''
                                    }

                                    if (record.productNumber != undefined && record.status == undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0.01}
                                                    value={record.quantity}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].quantity = val
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.quantity = record.quantity
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                                if (tableData[i].children[j].kid == record.kid) {
                                                                    tableData[i].children[j].children = record.children
                                                                    stop = true
                                                                }
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0.01}
                                            value={record.quantity}
                                            onChange={(val) => {
                                                record.quantity = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            // {
                            //     title: '销售金额',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },

                            {
                                title: '备注',
                                dataIndex: 'spec',
                                key: 'spec',
                                ellipsis: true,
                                width: 120,
                                render: (text, record) => {
                                    if (record.isEmptyRecord || record.number == undefined) {
                                        return ''
                                    }
                                    return (<Input.TextArea
                                        style={{width: 180}}
                                        defaultValue={record.spec}
                                        onChange={(e => {
                                            record.spec = e.target.value
                                        })}
                                        rows={1}
                                    />)
                                }

                            },
                            {
                                title: `状态`,
                                dataIndex: 'status',
                                key: 'status',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    if (record.isEmptyRecord || record.status == undefined) {
                                        return ''
                                    }

                                    return (
                                        <Radio.Group value={text} onChange={e => {
                                            if (record.children && record.children.length) {
                                                record.children.map(item => {
                                                    item.status = e.target.value
                                                })
                                            }
                                            record.status = e.target.value
                                            setFlag(flag + 1)
                                        }}>
                                            <Radio value={1}>禁用</Radio>
                                            <Radio value={2}>启用</Radio>
                                        </Radio.Group>)
                                }
                            },
                            {
                                title: `操作`,
                                dataIndex: 'status',
                                key: 'status',
                                ellipsis: true,
                                width: 80, render: (text, record, index) => {
                                    if (record.isEmptyRecord || record.status == undefined) {
                                        return ''
                                    }
                                    return (
                                        <Button type='link' onClick={() => {
                                            let stop = false
                                            for (let i = 0; i < tableData.length && !stop; i++) {
                                                for (let j = 0; j < tableData[i].children.length && !stop; j++) {
                                                    for (let w = 0; w < tableData[i].children[j].children.length && !stop; w++) {
                                                        if (tableData[i].children[j].children[w].kid == record.kid) {
                                                            tableData[i].children[j].children.splice(w, 1)
                                                            // tableData[i].children[j].children? = record.children
                                                            stop = true
                                                        }
                                                    }

                                                }
                                            }
                                            setFlag(flag + 1)
                                            setTableData(tableData)
                                        }}>删除</Button>
                                    )
                                }
                            }
                        ]}
                        dataSource={[
                            ...tableData,
                            {
                                id: '',
                                isEmptyRecord: true,
                            }
                        ]}
                        expandable={{
                            defaultExpandAllRows: true
                            // indentSize: 0
                        }}
                        scroll={{x: '100%'}}
                        pagination={false}
                    /> :
                    <Table
                        rowKey={record => record.kid}
                        columns={[
                            {
                                title: '部位',
                                dataIndex: 'positionName',
                                key: 'positionName',
                                ellipsis: true,
                                width: 80,
                                render: (text, record) => {
                                    if (record.isEmptyRecord) {
                                        return <Input
                                            style={{
                                                whiteSpace: 'pre-wrap',
                                                marginLeft: tableData.length ? '-20px' : '0'
                                            }}
                                            ref={sRef}
                                            autoComplete="off"
                                            placeholder={"输入编号 回车"}
                                            addonAfter={
                                                <SelectOutlined
                                                    onClick={() => {
                                                        setProductListShow(true)
                                                        // console.log(sRef.current.state.value)
                                                        // setModalNumber(1)
                                                        // setModalTitle(action === Sale.TYPE_BOUTIQUE ? '精品选择' : (action === Sale.TYPE_PART ? '配件选择' : "商品选择"))
                                                        // setVisible(true)
                                                    }}/>
                                            }
                                            onKeyDown={(e) => {
                                                if (sRef.current.state.value && sRef.current.state.value.length > 0 && e.keyCode === 13) { // Enter
                                                    // ajax查询数据
                                                    // getData(newValue)
                                                    // console.log(sRef.current.state.value)
                                                }
                                            }}
                                        />
                                    } else {
                                        return text
                                    }
                                }
                            },
                            {
                                title: '商品编号',
                                dataIndex: 'number',
                                key: 'number',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '商品名称',
                                dataIndex: 'name',
                                key: 'name',
                                ellipsis: true,
                                width: 120,
                            },
                            {
                                title: '计量单位',
                                dataIndex: 'unit',
                                key: 'unit',
                                ellipsis: true,
                                width: 80,
                            },
                            // {
                            //     title: '最新进价',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },
                            {
                                title: '轿车出库用量',
                                dataIndex: 'carQuantity',
                                key: 'carQuantity',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    if (record.positionName != undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0.01}
                                                    defaultValue={record.carQuantity}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].carQuantity = val
                                                                stop = true
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.carQuantity = record.carQuantity
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].children = record.children
                                                                stop = true
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0.01}
                                            value={record.carQuantity}
                                            onChange={(val) => {
                                                record.carQuantity = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            {
                                title: '商务车出库用量',
                                dataIndex: 'commercialQuantity',
                                key: 'commercialQuantity',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    if (record.positionName != undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0.01}
                                                    value={record.commercialQuantity}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].commercialQuantity = val
                                                                stop = true
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.commercialQuantity = record.commercialQuantity
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].children = record.children
                                                                stop = true
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0.01}
                                            value={record.commercialQuantity}
                                            onChange={(val) => {
                                                record.commercialQuantity = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            // {
                            //     title: '轿车成本',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },
                            // {
                            //     title: '商务车成本',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },
                            {
                                title: '零售价',
                                dataIndex: 'salePrice',
                                key: 'salePrice',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    // return text
                                    if (record.positionName != undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0}
                                                    value={record.salePrice}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].salePrice = val
                                                                stop = true
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.salePrice = record.salePrice
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].children = record.children
                                                                stop = true
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0}
                                            value={record.salePrice}
                                            onChange={(val) => {
                                                record.salePrice = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            {
                                title: '销售数量',
                                dataIndex: 'quantity',
                                key: 'quantity',
                                ellipsis: true,
                                width: 150,
                                render: (text, record, index) => {
                                    // return text
                                    if (record.positionName != undefined) {
                                        return (
                                            <div style={{display: 'flex'}}>
                                                <InputNumber
                                                    style={{width: '80%'}}
                                                    onPressEnter={wmsPublic.onPressEnter}
                                                    precision={2}
                                                    min={0.01}
                                                    value={record.quantity}
                                                    onBlur={(val) => {
                                                        val = val.target.value
                                                        let stop = false
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].quantity = val
                                                                stop = true
                                                            }
                                                        }
                                                        setTableData(tableData)
                                                        setFlag(flag + 1)
                                                    }}
                                                />
                                                <Button onClick={() => {
                                                    if (record.children && record.children.length) {
                                                        let stop = false
                                                        record.children.map(item => {
                                                            item.quantity = record.quantity
                                                        })
                                                        for (let i = 0; i < tableData.length && !stop; i++) {
                                                            if (tableData[i].kid == record.kid) {
                                                                tableData[i].children = record.children
                                                                stop = true
                                                            }
                                                        }
                                                    }
                                                    setTableData(tableData)
                                                    setFlag(flag + 1)
                                                }}>批量</Button>
                                            </div>
                                        )
                                    }
                                    return (
                                        <InputNumber
                                            onPressEnter={wmsPublic.onPressEnter}
                                            precision={2}
                                            min={0.01}
                                            value={record.quantity}
                                            onChange={(val) => {
                                                record.quantity = val
                                                setFlag(flag + 1)
                                            }}
                                        />)
                                }
                            },
                            {
                                title: '备注',
                                dataIndex: 'spec',
                                key: 'spec',
                                ellipsis: true,
                                width: 120,
                                render: (text, record) => {
                                    if (record.isEmptyRecord || record.number == undefined) {
                                        return ''
                                    }
                                    return (<Input.TextArea
                                        style={{width: 180}}
                                        defaultValue={record.spec}
                                        onChange={(e => {
                                            record.spec = e.target.value
                                        })}
                                        rows={1}
                                    />)
                                }

                            },
                            // {
                            //     title: '销售金额',
                            //     dataIndex: 'unit',
                            //     key: 'unit',
                            //     ellipsis: true,
                            //     width: 100,
                            // },
                            {
                                title: `状态`,
                                dataIndex: 'status',
                                key: 'status',
                                ellipsis: true,
                                width: 120,
                                render: (text, record, index) => {
                                    if (record.isEmptyRecord || record.status == undefined) {
                                        return ''
                                    }

                                    return (
                                        <Radio.Group value={text} onChange={e => {
                                            if (record.children && record.children.length) {
                                                record.children.map(item => {
                                                    item.status = e.target.value
                                                })
                                            }
                                            record.status = e.target.value
                                            setFlag(flag + 1)
                                        }}>
                                            <Radio value={1}>禁用</Radio>
                                            <Radio value={2}>启用</Radio>
                                        </Radio.Group>)
                                }
                            }
                        ]}
                        dataSource={[
                            ...tableData,
                            // {
                            //     id: '',
                            //     isEmptyRecord: true,
                            // }
                        ]}
                        expandable={{
                            defaultExpandAllRows: true
                            // indentSize: 0
                        }}
                        scroll={{x: '100%'}}
                        pagination={false}
                    />
            }

            <Modal
                maskClosable={false}
                title="选择精品"
                visible={productListShow}
                width={1000}
                footer={null}
                onCancel={() => {
                    setProductListShow(false)
                }}>
                <LookupProductGoods
                    defaultValue={'goods'}
                    isMultiple={true}
                    onOk={(val) => {
                        doList(val.map(item => {
                            return {...item, productId: item.id, from: 'lookup'}
                        }))
                    }}/>
            </Modal>
        </>
    )
}

export default InstallPackage