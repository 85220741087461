import React, {useEffect, useState} from 'react'
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Table} from "antd";
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import {connect} from "react-redux"
import TermForm from "./form"
import {Term, wmsPublic} from "../../config";

function TermManage(props) {
    // taxonomy 分类类型
    let {isCompany = false, taxonomy = Term.TAXONOMY_ONE} = props

    let [list, setList] = useState([]) // 列表数据
    let [expandAllRows, setExpandAllRows] = useState([]) // 展开的行
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [term, setTerm] = useState({}) // 编辑的数据

    const TAXONOMY_NAMES = {
        [Term.TAXONOMY_TWO]: "集团统计分类",
        [Term.TAXONOMY_ONE]: "集团管理分类",
        [Term.PRODUCT_CATEGORY]: "配件管理分类",
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: 150,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) => (
                text in Term.StatusAlias ? Term.StatusAlias[text] : text
            )
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'employeeName',
            width: 100,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 100,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
        {
            title: '操作',
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record) => (
                <div className={"action-btns"}>
                    <Button type={"link"} onClick={(e) => {
                        if ((record.taxonomy !== Term.PRODUCT_CATEGORY) || (record.taxonomy === Term.PRODUCT_CATEGORY && record.ownerId !== record.tenantId)) {
                            e.stopPropagation()
                            setTerm(record)
                            setVisible(true)
                        }
                    }}>编辑</Button>
                </div>)
        }
    ]

    // 获取车系列表
    let getData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: taxonomy,
            ownerId: isCompany ? common.getUser().company.id : ""
        }).then(data => {
            // 组装数据
            setExpandAllRows(data.terms.map(item => item.id))
            setList(wmsPublic.getTree(data.terms, ''))
        }).finally(() => {
            common.loadingStop()
        })
    };

    // 修改
    let update = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/term/updateById", params).then(() => {
            common.toast("更改成功")
            setVisible(false)
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 新增
    let create = (params) => {
        common.loadingStart();
        common.ajax('post', '/wms/term/create', params)
            .then(data => {
                common.toast("新增成功")
                setVisible(false)
                getData()
            }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [])

    return (
        <div className={'wms'}>
            <PageTop title={TAXONOMY_NAMES[taxonomy]}>
                <Button icon={<PlusOutlined/>} type="primary"
                        onClick={() => {
                            setTerm({})
                            setVisible(true)
                        }}>新增</Button>
            </PageTop>
            <Table
                onExpand={(expanded, record) => {
                    if (expanded) {
                        setExpandAllRows(expandAllRows.concat(record.id))
                    } else {
                        let arr = []
                        expandAllRows.forEach((item) => {
                            if (item !== record.id) {
                                arr.push(item)
                            }
                        })
                        setExpandAllRows(arr)
                    }
                }}
                expandedRowKeys={expandAllRows}
                expandRowByClick={true}
                columns={columns}
                dataSource={list}
                scroll={{x: '100%'}}
                pagination={false}
                rowKey="id"/>
            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <TermForm
                    action={taxonomy}
                    defaultValue={term}
                    isCompany={isCompany}
                    onOk={(val) => {
                        if (Object.keys(term).length === 0) {
                            create(val)
                        } else {
                            update(val)
                        }
                    }}/>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermManage)