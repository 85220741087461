import React, {useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom"
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import {Button, Space, Row, Col, DatePicker, Input, Select, Table, Pagination, Tabs, Image, Empty} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, SelectOutlined} from '@ant-design/icons'
import common from '../../../../utils/common'

function AppBackground() {
    const inputRef = useRef(null)
    const [warrantySwiper, setWarrantySwiper] = useState([])
    const [warrantyBottom, setWarrantyBottom] = useState([])
    const [calendarBackground, setCalendarBackground] = useState([])
    const [kachaTarget, setKachaTarget] = useState('')
    const onInit = (type) => {
        if (type == undefined || type == 'app_accessory_slideshow') {
            // 轮播图(app_accessory_slideshow)
            common.ajax('get', '/support/images/list', {
                type: 'app_accessory_slideshow',
                ownerId: common.getUser().company.id
            }).then((res) => {
                if (res && res.length) {
                    setWarrantySwiper(res.map(item => item.url))
                }
            }).finally(() => {})
        }
        if (type == undefined || type == 'app_accessory_background_image') {
            // 背景图片(app_accessory_background_image)
            common.ajax('get', '/support/images/list', {
                type: 'app_accessory_background_image',
                ownerId: common.getUser().company.id
            }).then((res) => {
                if (res && res.length) {
                    setWarrantyBottom(res.map(item => item.url))
                }
            }).finally(() => {})
        }
        if (type == undefined || type == 'app_calendar_background_image') {
            // 背景图片(app_calendar_background_image)
            common.ajax('get', '/support/images/list', {
                type: 'app_calendar_background_image',
                ownerId: common.getUser().company.id
            }).then((res) => {
                if (res && res.length) {
                    setCalendarBackground(res.map(item => item.url))
                }
            }).finally(() => {})
        }
    }
    const handleImageChange = (e) => {
        // console.log(e)
        // console.log(e.target.files)
        let es = []
        let index = 0
        loopOp(Object.values(e.target.files), 0, [], (es) => {
            inputRef.current.value = ''
            onUpload(es)
        })
    };

    const loopOp = (files, index, out, cb) => {
        if (index < files.length) {
            const file = files[index];  // 这是上传的文件流数据
            // const isLt20M = file.size / 1024 / 1024 < 20; // 限制20M以内
            // const windowURL = window.URL || window.webkitURL;// 实现预览
            // const dataURl = windowURL.createObjectURL(file); // 硬盘或sd卡指向文件路径， 可以直接用于预览的地址
            // 我需要本地缓存文件，所以使用了base64格式
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => {
                // e.target.result  这是base64格式的文件
                // 其他处理， 可以从这里获取其他方式，如文件url路径、Blob格式的文件
                loopOp(files, index + 1, [...out, e.target.result], cb)
            }
        } else {
            cb(out)
        }
    }

    const onUpload = async (base64) => {
        let rrs = []
        // let rrs = [
        //     "https://erpauto.oss-cn-chengdu.aliyuncs.com/temp/f1b46af8436a47e7a7a3ffa53ab263b9.png",
        //     "https://erpauto.oss-cn-chengdu.aliyuncs.com/temp/89a8b5ff25504adf97f871150ab822a0.png",
        //     "https://erpauto.oss-cn-chengdu.aliyuncs.com/temp/259dcb8167294f909cd26550b79c6b8e.png"
        // ]

        common.loadingStart()
        for (let i = 0;i < base64.length;i++) {
            let url = await common.ajax('post', '/wms/oss/uploadBase', {
                "image": base64[i]
            })
            // .then((res) => {
            //     if (res) {
            //         // onSaveImgs([...imgs, res])
            //         setImgs([...imgs, res])
            //     }
            // }).finally(common.loadingStop)
            if (url) {
                rrs.push(url)
            }
            if (i == base64.length - 1) {
                common.loadingStop()
            }
        }
        if (kachaTarget == 'app_accessory_slideshow') {
            setWarrantySwiper([...warrantySwiper, ...rrs])
        } else if (kachaTarget == 'app_accessory_background_image') {
            setWarrantyBottom(rrs)
        } else if (kachaTarget == 'app_calendar_background_image') {
            setCalendarBackground(rrs)
        }
    }

    const onKaCha = (type) => {
        setKachaTarget(type)
        inputRef.current.click();
    };
    const onSave = (type) => {
        let _target = []
        if (type == 'app_accessory_slideshow') {
            _target = [...warrantySwiper]
        } else if (type == 'app_accessory_background_image') {
            _target = [...warrantyBottom]
        } else if (type == 'app_calendar_background_image') {
            _target = [...calendarBackground]
        }
        if (_target.length) {
            _target = _target.map(item => {return {name: type, url: item}})
            common.ajax('post', '/support/images/create', {
                images: _target,
                type,
                ownerId: common.getUser().company.id
            }).then((res) => {
                onInit(type)
            }).finally(() => {})
        }
    }
    useEffect(() => {
        onInit()
    }, [])
    return (
        <>
            <PageTop title="上传照片" ></PageTop>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="公众号质保背景" key="1">
                    <div><h4>质保顶部轮播图<Button style={{marginLeft: '20px'}} onClick={() => {onKaCha('app_accessory_slideshow')}}>添加图片</Button><Button type='primary' style={{marginLeft: '10px'}} onClick={() => {onSave('app_accessory_slideshow')}}>保存配置</Button></h4></div>
                    <Image.PreviewGroup
                        items={warrantySwiper}
                    >
                        <Row gutter={24}>
                            {
                                warrantySwiper.map((img, index) => {
                                    return (
                                        <div style={{padding: '10px'}}>
                                            <Image
                                                width={300}
                                                height={300}
                                                src={img}
                                            />
                                        </div>
                                    )
                                })
                            }
                            {
                                warrantySwiper.length == 0 && <Empty description='暂无图片' />
                            }
                        </Row>
                    </Image.PreviewGroup>
                    <div><h4>质保底部背景图<Button style={{marginLeft: '20px'}} onClick={() => {onKaCha('app_accessory_background_image')}}>更改图片</Button><Button type='primary' style={{marginLeft: '10px'}} onClick={() => {onSave('app_accessory_background_image')}}>保存配置</Button></h4></div>
                    <Image.PreviewGroup
                        items={warrantyBottom}
                    >
                        <Row gutter={24}>
                            {
                                warrantyBottom.map((img, index) => {
                                    return (
                                        <div style={{padding: '10px'}}>
                                            <Image
                                                width={300}
                                                height={300}
                                                src={img}
                                            />
                                        </div>
                                    )
                                })
                            }
                            {
                                warrantyBottom.length == 0 && <Empty description='暂无图片' />
                            }
                        </Row>
                    </Image.PreviewGroup>

                </Tabs.TabPane>

                <Tabs.TabPane tab="APP日历背景" key="2">
                    <div><h4>APP日历背景<Button style={{marginLeft: '20px'}} onClick={() => {onKaCha('app_calendar_background_image')}}>更改图片</Button><Button type='primary' style={{marginLeft: '10px'}} onClick={() => {onSave('app_calendar_background_image')}}>保存配置</Button></h4></div>
                    <Image.PreviewGroup
                        items={calendarBackground}
                    >
                        <Row gutter={24}>
                            {
                                calendarBackground.reverse().map((img, index) => {
                                    return (
                                        <div style={{padding: '10px'}}>
                                            <Image
                                                width={300}
                                                height={300}
                                                src={img}
                                            />
                                        </div>
                                    )
                                })
                            }
                            {
                                calendarBackground.length == 0 && <Empty description='暂无图片' />
                            }
                        </Row>
                    </Image.PreviewGroup>
                </Tabs.TabPane>
            </Tabs>
            <div>
                <Space>
                    <input
                        style={{display: 'none'}}
                        ref={inputRef} // 获取input元素，用于获取元素模拟点击事件
                        type="file"
                        multiple={kachaTarget == 'app_accessory_slideshow'}
                        accept={'image/jpeg,image/jpg,image/png,image/svg'}
                        // accept={'camera/*,image/jpeg,image/jpg,image/png,image/svg'}
                        // capture={'camera'}
                        onChange={handleImageChange} // 发生变化时调用
                    />

                </Space>
            </div>
        </>
    )
}

export default AppBackground